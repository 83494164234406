import React from 'react'
import { StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import { colors } from '../../styles/global'
import MyDayBotOptionsModalWrapper from './MyDayBotOptionsModalWrapper'
import AddTaskTag from '../../Tags/AddTaskTag'
import { FEED_TASK_OBJECT_TYPE } from '../../Feeds/Utils/FeedsConstants'
import SyncCalendar from './SyncCalendar'

export default function MyDayBotLine() {
    const defaultProjectId = useSelector(state => state.loggedUser.defaultProjectId)
    const currentSection = useSelector(state => state.taskViewToggleSection)
    const isMiddleScreen = useSelector(state => state.isMiddleScreen)

    const inOpenSection = currentSection === 'Open'
    const disablButtons = !defaultProjectId

    return (
        <View style={localStyles.container}>
            <View style={localStyles.leftArea}>
                <MyDayBotOptionsModalWrapper
                    projectId={defaultProjectId}
                    showAssistantDataButton={true}
                    disabled={disablButtons}
                />
            </View>
            <View style={localStyles.rightArea}>
                {inOpenSection && <SyncCalendar />}
                <MyDayBotOptionsModalWrapper projectId={defaultProjectId} disabled={disablButtons} />
                {inOpenSection && (
                    <AddTaskTag
                        projectId={defaultProjectId}
                        style={{ marginLeft: 8 }}
                        sourceType={FEED_TASK_OBJECT_TYPE}
                        disabled={disablButtons}
                        showProjectSelector={true}
                        forceShrink={isMiddleScreen}
                    />
                )}
            </View>
        </View>
    )
}

const localStyles = StyleSheet.create({
    container: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: colors.Grey100,
        marginTop: 16,
        paddingVertical: 8,
        borderRadius: 4,
        alignItems: 'center',
    },
    leftArea: {
        flexDirection: 'row',
    },
    rightArea: {
        flexDirection: 'row',
        paddingVertical: 6,
        marginRight: 16,
    },
})
