import { cloneDeep, intersection, isEqual, uniq } from 'lodash'
import { firebase } from '@firebase/app'
import moment from 'moment'

import {
    addUniqueInstanceTypeToArray,
    creatTaskFeedChain,
    createFollowUpBacklinksToNotes,
    createGenericTaskWhenMentionInTitleEdition,
    createMentionTasksAfterSetTaskPublic,
    createSubtasksCopies,
    deleteSubTaskFromParent,
    earnGold,
    feedsChainInStopObservingTask,
    generateNegativeSortIndex,
    generateNegativeSortTaskIndex,
    generateSortIndex,
    getDb,
    getId,
    getMentionedUsersIdsWhenEditText,
    getNoteMeta,
    getObjectFollowersIds,
    getTaskData,
    globalWatcherUnsub,
    insertFollowersUserToFeedChain,
    logDoneTasks,
    logEvent,
    mapTaskData,
    moveTasksinWorkflowFeedsChain,
    moveToTomorrowGoalReminderDateIfThereAreNotMoreTasks,
    processFollowersWhenEditTexts,
    registerTaskObservedFeeds,
    setTaskDueDateFeedsChain,
    setTaskParentGoalFeedsChain,
    setTaskProjectFeedsChain,
    setTaskToBacklogFeedsChain,
    tryAddFollower,
    updateStatistics,
    updateTaskFeedsChain,
    uploadNewSubTaskFeedsChain,
} from '../firestore'
import store from '../../../redux/store'
import { BatchWrapper } from '../../../functions/BatchWrapper/batchWrapper'
import {
    createSubtaskPromotedFeed,
    createTaskAssigneeChangedFeed,
    createTaskAssigneeEstimationChangedFeed,
    createTaskAssistantChanged,
    createTaskCheckedDoneFeed,
    createTaskDescriptionChangedFeed,
    createTaskFocusChangedFeed,
    createTaskHighlightedChangedFeed,
    createTaskNameChangedFeed,
    createTaskObserverEstimationChangedFeed,
    createTaskPrivacyChangedFeed,
    createTaskRecurrenceChangedFeed,
    createTaskReviewerEstimationChangedFeed,
    createTaskUncheckedDoneFeed,
} from './taskUpdates'
import { creatFollowUpTaskFeedChain } from './taskUpdatesChains'

import { FOLLOWER_TASKS_TYPE } from '../../../components/Followers/FollowerConstants'
import {
    setSelectedNavItem,
    setSelectedSidebarTab,
    setSelectedTasks,
    setSelectedTypeOfProject,
    startLoadingData,
    stopLoadingData,
    switchProject,
} from '../../../redux/actions'
import {
    WORKSTREAM_ID_PREFIX,
    getWorkstreamInProject,
    isWorkstream,
} from '../../../components/Workstreams/WorkstreamHelper'
import TasksHelper, {
    BACKLOG_DATE_NUMERIC,
    DONE_STEP,
    GENERIC_COMMENT_TYPE,
    GENERIC_TASK_TYPE,
    getTaskAutoEstimation,
    MAX_GOLD_TO_EARN_BY_CHECK_TASKS,
    OPEN_STEP,
    RECURRENCE_ANNUALLY,
    RECURRENCE_DAILY,
    RECURRENCE_EVERY_2_WEEKS,
    RECURRENCE_EVERY_3_MONTHS,
    RECURRENCE_EVERY_3_WEEKS,
    RECURRENCE_EVERY_6_MONTHS,
    RECURRENCE_EVERY_WORKDAY,
    RECURRENCE_MONTHLY,
    RECURRENCE_NEVER,
    RECURRENCE_WEEKLY,
} from '../../../components/TaskListView/Utils/TasksHelper'
import {
    chronoKeysOrder,
    getCommentDirectionWhenMoveTaskInTheWorklfow,
    getWorkflowStepId,
    getWorkflowStepsIdsSorted,
} from '../../HelperFunctions'
import {
    FORDWARD_COMMENT,
    MENTION_SPACE_CODE,
    STAYWARD_COMMENT,
    updateNewAttachmentsData,
} from '../../../components/Feeds/Utils/HelperFunctions'
import { getUserWorkflow } from '../../../components/ContactsView/Utils/ContactsHelper'
import { updateXpByDoneForAllReviewers, updateXpByDoneTask } from '../../Levels'
import { FEED_PUBLIC_FOR_ALL } from '../../../components/Feeds/Utils/FeedsConstants'
import ProjectHelper from '../../../components/SettingsView/ProjectsSettings/ProjectHelper'
import { tryToGenerateTopicAdvaice } from '../../assistantHelper'
import { getDvMainTabLink } from '../../LinkingHelper'
import { isPrivateNote } from '../../../components/NotesView/NotesHelper'
import { getGoalData } from '../Goals/goalsFirestore'
import { isPrivateGoal } from '../../../components/GoalsView/GoalsHelper'
import { getSkillData } from '../Skills/skillsFirestore'
import { isPrivateSkill } from '../../../components/SettingsView/Profile/Skills/SkillsHelper'
import { updateNotePrivacy, updateNoteTitleWithoutFeed } from '../Notes/notesFirestore'
import { updateChatPrivacy, updateChatTitleWithoutFeeds } from '../Chats/chatsFirestore'
import { createObjectMessage } from '../Chats/chatsComments'
import NavigationService from '../../NavigationService'
import { DV_TAB_ROOT_TASKS, DV_TAB_TASK_PROPERTIES } from '../../TabNavigationConstants'
import { getRoundedStartAndEndDates } from '../../../components/MyDayView/MyDayTasks/MyDayOpenTasks/myDayOpenTasksHelper'
import { getCalendarTaskStartAndEndTimestamp } from '../../../components/MyDayView/MyDayTasks/MyDayOpenTasks/myDayOpenTasksIntervals'

export async function watchTask(projectId, taskId, watcherKey, callback) {
    globalWatcherUnsub[watcherKey] = getDb()
        .doc(`items/${projectId}/tasks/${taskId}`)
        .onSnapshot(doc => {
            const taskData = doc.data()
            const task = taskData ? mapTaskData(doc.id, taskData) : null
            callback(task)
        })
}

export const updateTaskEditionData = async (projectId, taskId, editorId) => {
    await getDb().runTransaction(async transaction => {
        const ref = getDb().doc(`items/${projectId}/tasks/${taskId}`)
        const doc = await transaction.get(ref)
        if (doc.exists) transaction.update(ref, { lastEditionDate: Date.now(), lastEditorId: editorId })
    })
}

const updateEditionData = data => {
    const { loggedUser } = store.getState()
    data.lastEditionDate = Date.now()
    data.lastEditorId = loggedUser.uid
}

export async function updateTaskData(projectId, taskId, data, batch) {
    updateEditionData(data)
    const ref = getDb().doc(`items/${projectId}/tasks/${taskId}`)
    batch ? batch.update(ref, data) : await ref.update(data)
}

async function updateTaskDataDirectly(projectId, taskId, data, batch) {
    const ref = getDb().doc(`items/${projectId}/tasks/${taskId}`)
    batch ? batch.update(ref, data) : await ref.update(data)
}

export async function uploadNewTask(
    projectId,
    task,
    linkBack,
    awaitForTaskCreation,
    tryToGenerateBotAdvaice,
    notGenerateMentionTasks,
    notGenerateUpdates
) {
    if (task && task.name && task.name.trim() !== '') {
        updateEditionData(task)

        const { loggedUser } = store.getState()

        const taskId = task.id ? task.id : getId()
        task.userIds = [task.userId]
        task.currentReviewerId = task.userId
        task.stepHistory = [OPEN_STEP]

        if (linkBack) task.linkBack = linkBack

        const contact = TasksHelper.getContactInProject(projectId, task.userId)
        if (contact && !task.observersIds.includes(loggedUser.uid)) {
            task.observersIds.push(loggedUser.uid)
        }

        const isTemplateProject = loggedUser.templateProjectIds.includes(projectId)
        task.sortIndex = isTemplateProject ? generateNegativeSortIndex() : generateSortIndex()
        const taskCopy = { ...task, name: task.name.toLowerCase() }

        const { dueDateByObserversIds, estimationsByObserverIds } = TasksHelper.getDueDateAndEstimationsByObserversIds(
            taskCopy.observersIds
        )
        taskCopy.dueDateByObserversIds = dueDateByObserversIds
        taskCopy.estimationsByObserverIds = estimationsByObserverIds

        delete taskCopy.id
        delete taskCopy.projectId

        if (!notGenerateUpdates) creatTaskFeedChain(projectId, taskId, task)

        const project = ProjectHelper.getProjectById(projectId)
        const fullText = task.extendedName + ' ' + task.description
        const mentionedUserIds = intersection(project.userIds, getMentionedUsersIdsWhenEditText(fullText, ''))

        if (tryToGenerateBotAdvaice) {
            const followerIds = uniq([...mentionedUserIds, task.userId, task.creatorId])
            tryToGenerateTopicAdvaice(
                projectId,
                taskId,
                'tasks',
                taskCopy.isPublicFor,
                task.extendedName,
                followerIds,
                task.assistantId,
                task.creatorId
            )
        }

        if (!notGenerateMentionTasks) {
            createGenericTaskWhenMention(
                projectId,
                taskId,
                mentionedUserIds,
                GENERIC_TASK_TYPE,
                'tasks',
                task.assistantId
            )
        }

        awaitForTaskCreation
            ? await getDb().doc(`items/${projectId}/tasks/${taskId}`).set(taskCopy)
            : getDb().doc(`items/${projectId}/tasks/${taskId}`).set(taskCopy)

        logEvent('new_task', {
            taskOwnerUid: task.userId,
            estimation: task.estimations[OPEN_STEP],
        })
        return mapTaskData(taskId, task)
    }
    return null
}

export async function uploadNewSubTask(projectId, task, newSubTask, inFollowUpProcess, tryToGenerateBotAdvaice) {
    const subTask = { ...newSubTask }

    if (task && task.name && task.name.trim() !== '') {
        const batch = new BatchWrapper(getDb())

        delete subTask.id
        const newTaskId = getId()

        subTask.parentId = task.id
        subTask.isSubtask = true
        subTask.userId = task.userId
        subTask.userIds = task.userIds
        subTask.currentReviewerId = task.currentReviewerId
        subTask.stepHistory = task.stepHistory
        subTask.sortIndex = generateNegativeSortTaskIndex()
        subTask.parentDone = task.done
        subTask.inDone = task.inDone
        subTask.dueDate = task.dueDate
        subTask.completed = task.completed
        subTask.observersIds = task.observersIds
        subTask.dueDateByObserversIds = task.dueDateByObserversIds
        subTask.estimationsByObserverIds = task.estimationsByObserverIds
        subTask.parentGoalId = task.parentGoalId
        subTask.parentGoalIsPublicFor = task.parentGoalIsPublicFor
        subTask.lockKey = task.lockKey
        subTask.assistantId = task.assistantId

        updateEditionData(subTask)
        batch.set(getDb().collection(`items/${projectId}/tasks`).doc(newTaskId), {
            ...subTask,
            name: subTask.name.toLowerCase(),
        })

        updateTaskData(
            projectId,
            task.id,
            { subtaskIds: [...task.subtaskIds, newTaskId], subtaskNames: [...task.subtaskNames, subTask.name] },
            batch
        )

        batch.commit()

        subTask.id = newTaskId
        uploadNewSubTaskFeedsChain(projectId, task, subTask, inFollowUpProcess)

        const project = ProjectHelper.getProjectById(projectId)
        const fullText = subTask.extendedName + ' ' + subTask.description
        const mentionedUserIds = intersection(project.userIds, getMentionedUsersIdsWhenEditText(fullText, ''))

        if (tryToGenerateBotAdvaice) {
            const followerIds = uniq([...mentionedUserIds, subTask.userId, subTask.creatorId])
            tryToGenerateTopicAdvaice(
                projectId,
                newTaskId,
                'tasks',
                subTask.isPublicFor,
                subTask.extendedName,
                followerIds,
                subTask.assistantId,
                task.creatorId
            )
        }

        logEvent('new_task', {
            taskOwnerUid: task.userId,
            estimation: task.estimations[OPEN_STEP],
            isSubtask: true,
        })
        return mapTaskData(newTaskId, subTask)
    }

    return null
}

export async function createRecurrentTask(projectId, taskId) {
    const task = await getTaskData(projectId, taskId)
    const recurrence = task.recurrence

    if (recurrence !== RECURRENCE_NEVER) {
        const today = moment().isoWeekday()
        let date = moment()
        // if today is Friday, Saturday or Sunday
        if (today >= 5) {
            // Set the date as monday of next week
            date.add(1, 'weeks').isoWeekday(1)
        } else {
            // Set the date as the next day
            date.add(1, 'days')
        }
        const recurrenceMap = {
            [RECURRENCE_DAILY]: moment().add(1, 'days'),
            [RECURRENCE_EVERY_WORKDAY]: date,
            [RECURRENCE_WEEKLY]: moment().add(1, 'weeks'),
            [RECURRENCE_EVERY_2_WEEKS]: moment().add(2, 'weeks'),
            [RECURRENCE_EVERY_3_WEEKS]: moment().add(3, 'weeks'),
            [RECURRENCE_MONTHLY]: moment().add(1, 'months'),
            [RECURRENCE_EVERY_3_MONTHS]: moment().add(3, 'months'),
            [RECURRENCE_EVERY_6_MONTHS]: moment().add(6, 'months'),
            [RECURRENCE_ANNUALLY]: moment().add(1, 'years'),
        }

        delete task.id

        const endOfToday = moment().endOf('day').valueOf()
        const endExpectedDay = moment(task.dueDate).endOf('day').valueOf()
        if (endOfToday <= endExpectedDay) {
            task.timesDoneInExpectedDay += 1
        } else {
            task.timesDoneInExpectedDay = 0
        }
        task.timesDone += 1

        task.done = false
        task.inDone = false
        task.created = moment().valueOf()
        task.dueDate = recurrenceMap[recurrence].valueOf()
        task.completed = null
        task.comments = []
        task.timesPostponed = 0
        task.completedTime = null
        task.lockKey = ''

        // When the task to delete is a sub task
        if (task.parentId !== null) {
            deleteSubTaskFromParent(projectId, taskId, task)
        }
        task.parentId = null
        task.isSubtask = false

        const subtaskIds = cloneDeep(task.subtaskIds)
        task.subtaskIds = []

        uploadNewTask(projectId, task, null, null, false, false, false).then(newTask => {
            if (subtaskIds !== null && subtaskIds.length > 0) {
                createSubtasksCopies(
                    projectId,
                    projectId,
                    newTask.id,
                    newTask,
                    subtaskIds,
                    { timesPostponed: 0 },
                    false,
                    true
                )
            }

            updateTaskData(
                projectId,
                taskId,
                { recurrence: RECURRENCE_NEVER, timesDoneInExpectedDay: 0, timesDone: 0 },
                null
            )
        })
    }
}

export async function uploadTaskByQuill(projectId, task, externalBatch) {
    updateEditionData(task)
    const taskId = task.id
    task.sortIndex = generateSortIndex()
    delete task.id
    externalBatch.set(getDb().doc(`items/${projectId}/tasks/${taskId}`), task)
}

export function createGenericTaskWhenMention(
    projectId,
    parentObjectId,
    mentionedUserIds,
    genericType,
    parentType,
    assistantId
) {
    if (mentionedUserIds.length > 0) {
        const { loggedUser } = store.getState()
        const { uid, displayName } = loggedUser

        const nonDuplicatedMentionedUsersIds = []

        mentionedUserIds.map(uid => {
            if (!nonDuplicatedMentionedUsersIds.includes(uid) && TasksHelper.getUserInProject(projectId, uid)) {
                nonDuplicatedMentionedUsersIds.push(uid)
            }
        })
        const path = `${window.location.origin}${getDvMainTabLink(
            projectId,
            parentObjectId,
            parentType === 'topics' ? 'chats' : parentType
        )}`
        const generic = genericType === GENERIC_COMMENT_TYPE ? `&Comment of ` : ''

        nonDuplicatedMentionedUsersIds.forEach(async userId => {
            let isPrivate = false
            if (parentType === 'tasks') {
                const task = await getTaskData(projectId, parentObjectId)
                isPrivate = TasksHelper.isPrivateTask(task, { uid: userId })
            } else if (parentType === 'notes') {
                const note = await getNoteMeta(projectId, parentObjectId)
                isPrivate = isPrivateNote(note, { uid: userId })
            } else if (parentType === 'goals') {
                const goal = await getGoalData(projectId, parentObjectId)
                isPrivate = isPrivateGoal(goal, userId)
            } else if (parentType === 'skills') {
                const skill = await getSkillData(projectId, parentObjectId)
                isPrivate = isPrivateSkill(skill, userId)
            }

            if (!isPrivate) {
                const genericTask = TasksHelper.getNewDefaultTask()

                genericTask.userId = userId
                genericTask.userIds = [userId]
                genericTask.currentReviewerId = userId
                genericTask.name = `@${displayName}  in ${generic}${path}`.toLowerCase()
                genericTask.extendedName = `@${displayName.replaceAll(' ', MENTION_SPACE_CODE)}${
                    loggedUser.isAnonymous ? '' : `#${uid}`
                }  in ${generic}${path}`
                genericTask.genericData = {
                    genericType,
                    parentType,
                    parentObjectId,
                    assistantId,
                }
                genericTask.sortIndex = generateSortIndex()
                updateEditionData(genericTask)
                uploadNewTask(projectId, genericTask, null, null, false, true, false)
            }
        })
    }
}

async function copyChatsForFolloupTaskAndGenerateCommentsData(projectId, oldTaskId, newTaskId) {
    let commentsData = null

    const oldChat = (await getDb().doc(`chatObjects/${projectId}/chats/${oldTaskId}`).get()).data()

    if (oldChat) {
        let lastCommentOwnerId = ''

        const commentDocs = await getDb().collection(`chatComments/${projectId}/tasks/${oldTaskId}/comments`).get()

        commentsData =
            commentDocs.docs.length > 0
                ? {
                      lastComment: '',
                      lastCommentType: STAYWARD_COMMENT,
                      amount: 0,
                  }
                : null

        const batch = new BatchWrapper(getDb())
        commentDocs.forEach(doc => {
            const comment = doc.data()
            if (!comment.commentText.includes('Follow up task created')) {
                commentsData.lastComment = comment.commentText
                commentsData.amount++
                lastCommentOwnerId = comment.creatorId
                batch.set(getDb().doc(`chatComments/${projectId}/tasks/${newTaskId}/comments/${doc.id}`), comment)
            }
        })

        batch.set(getDb().doc(`chatObjects/${projectId}/chats/${newTaskId}`), {
            ...oldChat,
            commentsData: { ...commentsData, lastCommentOwnerId },
        })
        await batch.commit()
    }

    return commentsData
}

export async function createFollowUpTask(projectId, task, dueDate, comment, newEstimation) {
    const { loggedUser } = store.getState()

    const newTaskId = getId()

    const commentsData = await copyChatsForFolloupTaskAndGenerateCommentsData(projectId, task.id, newTaskId)

    const followUpTask = {
        ...TasksHelper.getNewDefaultTask(),
        id: newTaskId,
        creatorId: loggedUser.uid,
        dueDate: dueDate,
        hasStar: task.hasStar,
        isPrivate: task.isPrivate,
        isPublicFor: task.isPublicFor,
        name: `#FollowUp ${task.name.replace(/#FollowUp/g, '')}`.toLowerCase(),
        extendedName: `#FollowUp ${(task.extendedName || task.name).replace(/#FollowUp/g, '')}`,
        userId: task.userId,
        userIds: [task.userId],
        currentReviewerId: task.userId,
        observersIds: task.observersIds,
        dueDateByObserversIds: task.dueDateByObserversIds,
        estimationsByObserverIds: task.estimationsByObserverIds,
        linkedParentTasksIds: task.linkedParentTasksIds,
        linkedParentNotesIds: task.linkedParentNotesIds,
        parentGoalId: task.parentGoalId,
        parentGoalIsPublicFor: task.parentGoalIsPublicFor,
        lockKey: task.lockKey,
        timesFollowed: task.timesFollowed ? task.timesFollowed + 1 : 1,
        commentsData,
        ...(task.noteId && { noteId: task.noteId }),
    }

    await uploadNewTask(projectId, followUpTask, null, null, true, true, true)

    updateTaskData(projectId, task.id, { timesFollowed: firebase.firestore.FieldValue.increment(1) }, null)

    if (task.subtaskIds && task.subtaskIds.length > 0) {
        createSubtasksCopies(projectId, projectId, newTaskId, followUpTask, [...task.subtaskIds], null, true, true)
    }

    const linkToNewTask = `${window.location.origin}/projects/${projectId}/tasks/${newTaskId}/properties`
    const commentOldTask = `Follow up task created: ${linkToNewTask}`
    createObjectMessage(projectId, task.id, commentOldTask, 'tasks', STAYWARD_COMMENT, null, null)

    if (comment && comment.trim()) {
        createObjectMessage(projectId, task.id, comment, 'tasks', STAYWARD_COMMENT, null, null)
        createObjectMessage(projectId, newTaskId, comment, 'tasks', STAYWARD_COMMENT, null, null)
    }

    createFollowUpBacklinksToNotes(projectId, newTaskId, task.id)

    creatFollowUpTaskFeedChain(projectId, task, newEstimation, followUpTask, newTaskId)
}

export async function updateTask(projectId, task, oldTask, oldAssignee, comment, commentMentions, isObservedTask) {
    const taskId = task.id
    const newAssignee = TasksHelper.getTaskOwner(task.userId, projectId)

    const taskToStore = { ...task, name: task.name.toLowerCase() }
    delete taskToStore.id
    delete taskToStore.time
    delete taskToStore.projectId

    const taskGoToDifferentList =
        task.userId !== oldTask.userId ||
        task.dueDate !== oldTask.dueDate ||
        task.parentGoalId !== oldTask.parentGoalId ||
        (task.parentId && task.recurrence !== oldTask.recurrence)
    if (taskGoToDifferentList) {
        taskToStore.sortIndex = generateSortIndex()
    }

    const needToPromoteSubtask = task.parentId && taskGoToDifferentList
    if (needToPromoteSubtask) {
        deleteSubTaskFromParent(projectId, taskId, task)
        taskToStore.parentId = null
        taskToStore.isSubtask = false
    }

    const batch = new BatchWrapper(getDb())

    if (task.parentId && !needToPromoteSubtask) {
        const parentRef = getDb().doc(`items/${projectId}/tasks/${task.parentId}`)
        const parentTask = (await parentRef.get()).data()

        let { subtaskIds, subtaskNames } = parentTask
        const subtaskIndex = subtaskIds.indexOf(task.id)
        subtaskNames[subtaskIndex] = task.name

        batch.update(parentRef, { subtaskNames })
    }

    const observersWereUpdated = !isEqual(task.observersIds, oldTask.observersIds)
    if (observersWereUpdated) {
        const {
            dueDateByObserversIds,
            estimationsByObserverIds,
        } = TasksHelper.mergeDueDateAndEstimationsByObserversIds(
            oldTask.dueDateByObserversIds,
            taskToStore.observersIds,
            oldTask.estimationsByObserverIds
        )

        taskToStore.dueDateByObserversIds = dueDateByObserversIds
        taskToStore.estimationsByObserverIds = estimationsByObserverIds
    }

    if (task.userId !== oldTask.userId && task.userId === task.suggestedBy) {
        taskToStore.suggestedBy = null
    }

    if (!isEqual(task.isPublicFor, oldTask.isPublicFor)) {
        updateChatPrivacy(projectId, task.id, 'tasks', task.isPublicFor)
        if (task.noteId) {
            getObjectFollowersIds(projectId, 'tasks', task.id).then(followersIds => {
                updateNotePrivacy(projectId, task.noteId, task.isPrivate, task.isPublicFor, followersIds, false, null)
            })
        }
    }

    if (task.recurrence === RECURRENCE_NEVER) {
        taskToStore.timesDoneInExpectedDay = 0
        taskToStore.timesDone = 0
    }

    const subtasksUpdateData = {
        isPrivate: taskToStore.isPrivate,
        isPublicFor: taskToStore.isPublicFor,
        dueDate: taskToStore.dueDate,
        observersIds: taskToStore.observersIds,
        dueDateByObserversIds: taskToStore.dueDateByObserversIds,
        estimationsByObserverIds: taskToStore.estimationsByObserverIds,
        parentGoalId: taskToStore.parentGoalId,
        parentGoalIsPublicFor: taskToStore.parentGoalIsPublicFor,
        lockKey: taskToStore.lockKey,
        suggestedBy: taskToStore.suggestedBy,
    }

    if (task.userId !== oldTask.userId) {
        subtasksUpdateData.userId = newAssignee.uid
        subtasksUpdateData.userIds = [newAssignee.uid]
        subtasksUpdateData.currentReviewerId = newAssignee.uid
    }

    if (task.dueDate > oldTask.dueDate) {
        taskToStore.timesPostponed = firebase.firestore.FieldValue.increment(1)
        subtasksUpdateData.timesPostponed = firebase.firestore.FieldValue.increment(1)
        logEvent('task_postponed')
    }

    const endOfToday = moment().endOf('day').valueOf()
    if (endOfToday < task.dueDate) {
        const assignee = TasksHelper.getUserInProject(projectId, task.userId)
        if (assignee.inFocusTaskId === task.id) updateFocusedTask(task.userId, projectId, null, null, null)
    }

    updateSubtasksState(projectId, task.subtaskIds, subtasksUpdateData, batch)
    updateTaskData(projectId, taskId, taskToStore, batch)

    // change statistic if task is Done
    if (task.done) {
        const oldEstimation = oldTask.estimations[OPEN_STEP] ? oldTask.estimations[OPEN_STEP] : 0
        const newEstimation = task.estimations[OPEN_STEP] ? task.estimations[OPEN_STEP] : 0

        if (newEstimation !== oldEstimation) {
            // Need to do two operation.
            // Doing only one operation with "newEstimation - oldEstimation" as parameter
            // will cause the Points estimation may not be accurate,
            // and resultant Point in BD may not MATCH with defined Points/Time constants
            updateStatistics(projectId, task.userId, oldEstimation, true, true, task.completed, batch)
            updateStatistics(projectId, task.userId, newEstimation, false, true, task.completed, batch)
        }
    }

    batch.commit()

    updateTaskFeedsChain(
        projectId,
        task,
        oldTask,
        oldAssignee,
        comment,
        commentMentions,
        taskId,
        newAssignee,
        isObservedTask
    )
}

export const setTaskAssistant = async (projectId, taskId, assistantId, needGenerateUpdate) => {
    updateTaskData(projectId, taskId, { assistantId }, null)
    if (needGenerateUpdate) createTaskAssistantChanged(projectId, assistantId, taskId, null, null)
}

export const setTaskNote = async (projectId, taskId, noteId) => {
    updateTaskData(projectId, taskId, { noteId }, null)
}

export async function setTaskPrivacy(projectId, taskId, isPrivate, isPublicFor, task) {
    updateTaskData(projectId, taskId, { isPrivate: isPrivate, isPublicFor: isPublicFor }, null)
    updateChatPrivacy(projectId, taskId, 'tasks', isPublicFor)
    if (task.noteId) {
        const followersIds = await getObjectFollowersIds(projectId, 'tasks', task.id)
        updateNotePrivacy(projectId, task.noteId, isPrivate, isPublicFor, followersIds, false, null)
    }
    task.subtaskIds.forEach(subtaskId => {
        setSubtaskPrivacy(projectId, subtaskId, isPrivate, isPublicFor)
    })

    const batch = new BatchWrapper(getDb())
    await createTaskPrivacyChangedFeed(projectId, taskId, isPrivate, isPublicFor, batch)
    const followTaskData = {
        followObjectsType: FOLLOWER_TASKS_TYPE,
        followObjectId: taskId,
        followObject: task,
        feedCreator: store.getState().loggedUser,
    }
    await tryAddFollower(projectId, followTaskData, batch)
    createMentionTasksAfterSetTaskPublic(projectId, task, isPrivate, isPublicFor)
    batch.commit()
}

export function setSubtaskPrivacy(projectId, taskId, isPrivate, isPublicFor) {
    updateTaskData(
        projectId,
        taskId,
        {
            isPrivate: isPrivate,
            isPublicFor: isPublicFor,
        },
        null
    )
}

export async function setTaskRecurrence(projectId, taskId, recurrence, task) {
    if (task.recurrence !== recurrence) {
        const batch = new BatchWrapper(getDb())
        const followTaskData = {
            followObjectsType: FOLLOWER_TASKS_TYPE,
            followObjectId: taskId,
            followObject: task,
            feedCreator: store.getState().loggedUser,
        }
        await tryAddFollower(projectId, followTaskData, batch)
        await createTaskRecurrenceChangedFeed(projectId, task, taskId, task.recurrence, recurrence)

        // When the task to update is a sub task
        if (task.parentId) {
            await deleteSubTaskFromParent(projectId, taskId, task, batch)
            updateTaskData(
                projectId,
                taskId,
                {
                    parentId: null,
                    isSubtask: false,
                    recurrence: recurrence,
                    sortIndex: generateSortIndex(),
                },
                batch
            )
        } else {
            const updateData = { recurrence: recurrence }
            if (recurrence === RECURRENCE_NEVER) {
                updateData.timesDoneInExpectedDay = 0
                updateData.timesDone = 0
            }
            updateTaskData(projectId, taskId, updateData, batch)
        }
        batch.commit()
        task.recurrence = recurrence
    }
}

export async function setTaskHighlight(projectId, taskId, highlightColor, task) {
    const batch = new BatchWrapper(getDb())
    const isHighlight = highlightColor.toLowerCase() !== '#ffffff'

    await createTaskHighlightedChangedFeed(projectId, task, taskId, isHighlight, batch)
    const followTaskData = {
        followObjectsType: FOLLOWER_TASKS_TYPE,
        followObjectId: taskId,
        followObject: task,
        feedCreator: store.getState().loggedUser,
    }
    await tryAddFollower(projectId, followTaskData, batch)

    updateTaskData(projectId, taskId, { hasStar: highlightColor }, batch)
    batch.commit()
}

export async function setTaskHighlightMultiple(highlightColor, tasks) {
    const batch = new BatchWrapper(getDb())
    const taskBatch = new BatchWrapper(getDb())
    const isHighlight = highlightColor.toLowerCase() !== '#ffffff'

    for (let task of tasks) {
        updateTaskData(task.projectId, task.id, { hasStar: highlightColor }, taskBatch)
    }
    taskBatch.commit()

    for (let task of tasks) {
        await createTaskHighlightedChangedFeed(task.projectId, task, task.id, isHighlight, batch)
        const followTaskData = {
            followObjectsType: FOLLOWER_TASKS_TYPE,
            followObjectId: task.id,
            followObject: task,
            feedCreator: store.getState().loggedUser,
        }
        await tryAddFollower(task.projectId, followTaskData, batch)
    }
    batch.commit()
}

export async function setTaskObserverEstimations(projectId, taskId, oldEstimation, newEstimation, observerId) {
    updateTaskData(projectId, taskId, { [`estimationsByObserverIds.${observerId}`]: newEstimation }, null)
    createTaskObserverEstimationChangedFeed(projectId, taskId, oldEstimation, newEstimation)
}

export async function setTaskEstimations(projectId, taskId, task, stepId, estimation) {
    const oldEstimation = task.estimations[stepId] ? task.estimations[stepId] : 0

    const batch = new BatchWrapper(getDb())
    if (oldEstimation !== estimation && stepId === OPEN_STEP && task.done) {
        // Need to do two operation.
        // Doing only one operation with "newEstimation - oldEstimation" as parameter
        // will cause the Points estimation may not be accurate,
        // and resultant Point in BD may not MATCH with defined Points/Time constants
        updateStatistics(projectId, task.userId, oldEstimation, true, true, task.completed, batch)
        updateStatistics(projectId, task.userId, estimation, false, true, task.completed, batch)
    }

    updateTaskData(projectId, task.id, { [`estimations.${stepId}`]: estimation }, batch)

    batch.commit()

    setFutureEstimationsFeedChain(projectId, taskId, task, stepId, estimation, oldEstimation)
}

async function setFutureEstimationsFeedChain(projectId, taskId, task, stepId, estimation, oldEstimation) {
    const batch = new BatchWrapper(getDb())

    stepId === OPEN_STEP
        ? await createTaskAssigneeEstimationChangedFeed(projectId, taskId, oldEstimation, estimation, batch)
        : await createTaskReviewerEstimationChangedFeed(
              projectId,
              task,
              taskId,
              oldEstimation,
              estimation,
              stepId,
              batch
          )

    const followTaskData = {
        followObjectsType: FOLLOWER_TASKS_TYPE,
        followObjectId: taskId,
        followObject: task,
        feedCreator: store.getState().loggedUser,
    }
    await tryAddFollower(projectId, followTaskData, batch)

    batch.commit()
}

export async function setTaskName(projectId, taskId, name, task, oldName) {
    const cleanedName = TasksHelper.getTaskNameWithoutMeta(name)

    const batch = new BatchWrapper(getDb())

    const mentionedUserIds = getMentionedUsersIdsWhenEditText(name, oldName)
    insertFollowersUserToFeedChain(mentionedUserIds, [], [], taskId, batch)

    createGenericTaskWhenMentionInTitleEdition(
        projectId,
        taskId,
        name,
        oldName,
        GENERIC_TASK_TYPE,
        'tasks',
        task.assistantId
    )

    updateTaskData(projectId, taskId, { name: cleanedName, extendedName: name.trim() }, batch)

    if (task.noteId) {
        await updateNoteTitleWithoutFeed(projectId, task.noteId, name, batch)
    }
    await updateChatTitleWithoutFeeds(projectId, taskId, name, batch)

    if (task.parentId) {
        const parentRef = getDb().doc(`items/${projectId}/tasks/${task.parentId}`)
        const parentTask = (await parentRef.get()).data()

        let { subtaskIds, subtaskNames } = parentTask
        const subtaskIndex = subtaskIds.indexOf(task.id)
        subtaskNames[subtaskIndex] = name

        updateTaskData(projectId, task.parentId, { subtaskNames }, batch)
    }

    await createTaskNameChangedFeed(projectId, task, oldName, name, taskId, batch)

    await processFollowersWhenEditTexts(projectId, FOLLOWER_TASKS_TYPE, taskId, task, mentionedUserIds, true, batch)

    batch.commit()
}

export async function setTaskDescription(projectId, taskId, description, task, oldDescription) {
    createGenericTaskWhenMentionInTitleEdition(
        projectId,
        taskId,
        description,
        oldDescription,
        GENERIC_TASK_TYPE,
        'tasks',
        task.assistantId
    )

    const batch = new BatchWrapper(getDb())

    updateTaskData(projectId, taskId, { description }, batch)
    const mentionedUserIds = getMentionedUsersIdsWhenEditText(description, oldDescription)
    insertFollowersUserToFeedChain(mentionedUserIds, [], [], taskId, batch)
    await createTaskDescriptionChangedFeed(projectId, task, oldDescription, description, taskId, batch)
    await processFollowersWhenEditTexts(projectId, FOLLOWER_TASKS_TYPE, taskId, task, mentionedUserIds, true, batch)

    batch.commit()
}

export async function setTaskAutoEstimation(projectId, task, autoEstimation, batch) {
    const { loggedUser } = store.getState()

    updateTaskData(projectId, task.id, { autoEstimation }, batch)

    const followTaskData = {
        followObjectsType: FOLLOWER_TASKS_TYPE,
        followObjectId: task.id,
        followObject: task,
        feedCreator: loggedUser,
    }

    tryAddFollower(projectId, followTaskData)
}

export async function setTaskAutoEstimationMultiple(tasks, autoEstimation) {
    const batch = new BatchWrapper(getDb())
    for (const task of tasks) {
        setTaskAutoEstimation(task.projectId, task, autoEstimation, batch)
    }
    batch.commit()
}

export function unfocusTaskInUsers(projectId, unfocusData, externalBatch) {
    const batch = externalBatch || new BatchWrapper(getDb())
    unfocusData.forEach(data => {
        const { userId, sortIndex } = data
        updateFocusedTask(userId, projectId, null, sortIndex, batch)
    })
    if (!externalBatch) batch.commit()
}

export const generateSortIndexForTaskInFocus = () => {
    const GAP = 1000000000000000
    return Number.MAX_SAFE_INTEGER - GAP
}

const generateSortIndexForTaskInFocusInTime = () => {
    return generateSortIndexForTaskInFocus() + generateSortIndex()
}

export async function updateFocusedTask(userId, projectId, task, sortIndexWhenUnfocus, externalBatch) {
    const assignee = TasksHelper.getUserInProject(projectId, userId)
    logEvent('focus_changed')

    if (assignee) {
        const batch = externalBatch || new BatchWrapper(getDb())

        if (task) {
            await setTaskDueDate(projectId, task.id, moment().valueOf(), task, false, batch, true)
            batch.update(getDb().doc(`items/${projectId}/tasks/${task.id}`), {
                sortIndex: generateSortIndexForTaskInFocusInTime(),
            })
        }

        if (assignee.inFocusTaskProjectId && assignee.inFocusTaskId) {
            batch.update(getDb().doc(`items/${assignee.inFocusTaskProjectId}/tasks/${assignee.inFocusTaskId}`), {
                sortIndex: sortIndexWhenUnfocus || generateSortIndex(),
            })
        }

        batch.update(getDb().doc(`users/${userId}`), {
            inFocusTaskId: task ? task.id : '',
            inFocusTaskProjectId: task ? projectId : '',
        })

        if (!externalBatch) batch.commit()

        if (assignee.inFocusTaskId) {
            createTaskFocusChangedFeed(assignee.inFocusTaskProjectId, assignee.inFocusTaskId, false, null, assignee)
        }
        if (task) {
            createTaskFocusChangedFeed(projectId, task.id, true, null, assignee)
        }
    }
}

export const updateTaskLastCommentData = async (projectId, taskId, lastComment, lastCommentType) => {
    getDb()
        .doc(`items/${projectId}/tasks/${taskId}`)
        .update({
            [`commentsData.lastComment`]: lastComment,
            [`commentsData.lastCommentType`]: lastCommentType,
            [`commentsData.amount`]: firebase.firestore.FieldValue.increment(1),
        })
}

export async function setTaskAssignee(
    projectId,
    taskId,
    uid,
    oldAssignee,
    newAssignee,
    task,
    generatedFeeds,
    externalBatch
) {
    const batch = externalBatch ? externalBatch : new BatchWrapper(getDb())

    if (generatedFeeds) {
        const { loggedUser: feedCreator } = store.getState()
        const feedChainFollowersIds = [feedCreator.uid]
        addUniqueInstanceTypeToArray(feedChainFollowersIds, newAssignee.uid)
        batch.feedChainFollowersIds = { [taskId]: feedChainFollowersIds }

        await createTaskAssigneeChangedFeed(projectId, task, newAssignee, oldAssignee, taskId, batch)
        const followTaskData = {
            followObjectsType: FOLLOWER_TASKS_TYPE,
            followObjectId: taskId,
            followObject: task,
            feedCreator,
        }
        await tryAddFollower(projectId, followTaskData, batch)
        if (feedCreator.uid !== newAssignee.uid && !isWorkstream(newAssignee.uid)) {
            followTaskData.feedCreator = newAssignee
            await tryAddFollower(projectId, followTaskData, batch)
        }
    }

    if (task.parentId) {
        await deleteSubTaskFromParent(projectId, task.id, task, batch)
    }

    const isPublicFor = [...task.isPublicFor]
    if (
        !isPublicFor.includes(FEED_PUBLIC_FOR_ALL) &&
        !isPublicFor.includes(newAssignee.uid) &&
        !isWorkstream(newAssignee.uid)
    ) {
        isPublicFor.push(newAssignee.uid)
    }

    const sugestedData = uid === task.suggestedBy ? { suggestedBy: null } : { suggestedBy: task.suggestedBy }

    const newObserversIds = task.observersIds.filter(uid => uid !== newAssignee.uid)

    if (task.userIds.length > 1) {
        // The task is in workflow, we need to reset the workflow back to open
        updateTaskData(
            projectId,
            taskId,
            {
                userId: uid,
                stepHistory: [OPEN_STEP],
                userIds: [uid],
                currentReviewerId: uid,
                parentId: null,
                isSubtask: false,
                isPublicFor: isPublicFor,
                observersIds: newObserversIds,
                sortIndex: generateSortIndex(),
                ...sugestedData,
            },
            batch
        )
    } else {
        updateTaskData(
            projectId,
            taskId,
            {
                userId: uid,
                userIds: [uid],
                currentReviewerId: uid,
                parentId: null,
                isSubtask: false,
                isPublicFor: isPublicFor,
                sortIndex: generateSortIndex(),
                observersIds: newObserversIds,
                ...sugestedData,
            },
            batch
        )
    }

    // change assignee of its subtasks
    if (task.subtaskIds?.length > 0) {
        for (let subtaskId of task.subtaskIds) {
            updateTaskData(
                projectId,
                subtaskId,
                {
                    userId: uid,
                    userIds: [uid],
                    currentReviewerId: uid,
                    isPublicFor: isPublicFor,
                    observersIds: newObserversIds,
                    sortIndex: generateSortIndex(),
                    ...sugestedData,
                },
                batch
            )
        }
    }

    if (!externalBatch) {
        await batch.commit()
        return await getTaskData(projectId, taskId)
    }
}

export async function setTaskAssigneeAndObservers(
    projectId,
    taskId,
    uid,
    observers,
    oldAssignee,
    newAssignee,
    task,
    generatedFeeds
) {
    const batch = new BatchWrapper(getDb())

    if (newAssignee.uid !== oldAssignee.uid) {
        await setTaskAssignee(projectId, taskId, uid, oldAssignee, newAssignee, task, generatedFeeds, batch)
    }

    const observersIds = observers.map(user => user.uid)

    const { dueDateByObserversIds, estimationsByObserverIds } = TasksHelper.mergeDueDateAndEstimationsByObserversIds(
        task.dueDateByObserversIds,
        observersIds,
        task.estimationsByObserverIds
    )

    const updateData = { observersIds, dueDateByObserversIds, estimationsByObserverIds }

    updateTaskData(projectId, taskId, updateData, batch)
    for (let subtaskId of task.subtaskIds) {
        updateTaskData(projectId, subtaskId, updateData, batch)
    }

    if (generatedFeeds) {
        await registerTaskObservedFeeds(projectId, { ...task, userId: newAssignee.uid, observersIds }, task, batch)

        const { loggedUser: feedCreator } = store.getState()
        const feedChainFollowersIds = [...observersIds]
        addUniqueInstanceTypeToArray(feedChainFollowersIds, feedCreator.uid)
        addUniqueInstanceTypeToArray(feedChainFollowersIds, newAssignee.uid)
        batch.feedChainFollowersIds = { [taskId]: feedChainFollowersIds }

        // await createTaskObserversChangedFeed(projectId, task, newAssignee, oldAssignee, taskId, batch)
        const followTaskData = {
            followObjectsType: FOLLOWER_TASKS_TYPE,
            followObjectId: taskId,
            followObject: task,
            feedCreator,
        }
        await tryAddFollower(projectId, followTaskData, batch)
        if (feedCreator.uid !== newAssignee.uid && !isWorkstream(newAssignee.uid)) {
            followTaskData.feedCreator = newAssignee
            await tryAddFollower(projectId, followTaskData, batch)
        }
    }

    batch.commit()
}

export async function setTaskAssigneeMultiple(tasks, oldAssignee, newAssignee) {
    const batch = new BatchWrapper(getDb())
    const taskBatch = new BatchWrapper(getDb())

    const promises = []
    for (let task of tasks) {
        if (task.parentId) {
            promises.push(deleteSubTaskFromParent(task.projectId, task.id, task, taskBatch))
        }

        // update "isPublicFor" field
        let isPublicFor = [...task.isPublicFor]
        let tmpIndex = isPublicFor.indexOf(oldAssignee.uid)
        if (tmpIndex >= 0) {
            isPublicFor[tmpIndex] = newAssignee.uid
        } else {
            isPublicFor.push(newAssignee.uid)
        }

        if (task.userIds.length > 1) {
            // The task is in workflow, we need to reset the workflow back to open
            updateTaskData(
                task.projectId,
                task.id,
                {
                    userId: newAssignee.uid,
                    stepHistory: [OPEN_STEP],
                    userIds: [newAssignee.uid],
                    currentReviewerId: newAssignee.uid,
                    parentId: null,
                    isSubtask: false,
                    isPublicFor: isPublicFor,
                    sortIndex: generateSortIndex(),
                },
                taskBatch
            )
        } else {
            updateTaskData(
                task.projectId,
                task.id,
                {
                    userId: newAssignee.uid,
                    userIds: [newAssignee.uid],
                    currentReviewerId: newAssignee.uid,
                    parentId: null,
                    isSubtask: false,
                    isPublicFor: isPublicFor,
                    sortIndex: generateSortIndex(),
                },
                taskBatch
            )
        }

        // change assignee of its subtasks
        if (task.subtaskIds?.length > 0) {
            for (let subtaskId of task.subtaskIds) {
                updateTaskData(
                    task.projectId,
                    subtaskId,
                    {
                        userId: newAssignee.uid,
                        userIds: [newAssignee.uid],
                        currentReviewerId: newAssignee.uid,
                        isPublicFor: isPublicFor,
                        sortIndex: generateSortIndex(),
                    },
                    taskBatch
                )
            }
        }
    }

    await Promise.all(promises)
    taskBatch.commit()

    for (let task of tasks) {
        const { loggedUser: feedCreator } = store.getState()
        const feedChainFollowersIds = [feedCreator.uid]
        addUniqueInstanceTypeToArray(feedChainFollowersIds, newAssignee.uid)
        batch.feedChainFollowersIds = { [task.id]: feedChainFollowersIds }

        await createTaskAssigneeChangedFeed(task.projectId, task, newAssignee, oldAssignee, task.id, batch)
        const followTaskData = {
            followObjectsType: FOLLOWER_TASKS_TYPE,
            followObjectId: task.id,
            followObject: task,
            feedCreator,
        }

        await tryAddFollower(task.projectId, followTaskData, batch)
        if (feedCreator.uid !== newAssignee.uid && !isWorkstream(newAssignee.uid)) {
            followTaskData.feedCreator = newAssignee
            await tryAddFollower(task.projectId, followTaskData, batch)
        }
    }
    batch.commit()
}

export async function setTaskProject(currentProject, newProject, task, oldAssignee, newAssignee) {
    const { loggedUser, projectUsers, route } = store.getState()

    const newProjectUsers = projectUsers[newProject.id]

    const taskCopy = { ...task }

    if (task.suggestedBy) {
        taskCopy.userId = loggedUser.uid
        taskCopy.suggestedBy = null
    }

    taskCopy.stepHistory = [OPEN_STEP]
    taskCopy.userIds = [task.userId]
    taskCopy.currentReviewerId = task.done ? DONE_STEP : task.userId
    taskCopy.observersIds = []
    taskCopy.dueDateByObserversIds = {}
    taskCopy.estimationsByObserverIds = {}
    taskCopy.parentGoalId = null
    taskCopy.parentGoalIsPublicFor = null
    taskCopy.lockKey = ''
    taskCopy.sortIndex = generateSortIndex()
    taskCopy.creatorId = newProjectUsers.map(user => user.uid).includes(taskCopy.creatorId)
        ? taskCopy.creatorId
        : loggedUser.uid
    if (task.parentId) {
        taskCopy.parentId = null
        taskCopy.isSubtask = false
        taskCopy.inDone = taskCopy.done
        taskCopy.parentDone = false
        taskCopy.completed = taskCopy.done ? Date.now() : null
    }

    const subtaskIds = taskCopy.subtaskIds
    taskCopy.subtaskIds = []
    taskCopy.subtaskNames = []

    updateEditionData(taskCopy)

    await getDb().doc(`items/${newProject.id}/tasks/${task.id}`).set(taskCopy)

    if (route === 'TaskDetailedView') {
        NavigationService.navigate('TaskDetailedView', {
            task: taskCopy,
            projectId: newProject.id,
        })

        const projectType = ProjectHelper.getTypeOfProject(loggedUser, newProject.id)
        store.dispatch([
            setSelectedSidebarTab(DV_TAB_ROOT_TASKS),
            switchProject(newProject.index),
            setSelectedTypeOfProject(projectType),
            setSelectedNavItem(DV_TAB_TASK_PROPERTIES),
        ])
    }
    const promises = []
    promises.push(
        createSubtasksCopies(currentProject.id, newProject.id, task.id, taskCopy, subtaskIds, null, false, false)
    )
    promises.push(
        getDb().doc(`items/${currentProject.id}/tasks/${task.id}`).update({ movingToOtherProjectId: newProject.id })
    )
    await Promise.all(promises)

    batch = new BatchWrapper(getDb())
    updateTaskData(currentProject.id, task.id, {}, batch)
    batch.delete(getDb().doc(`items/${currentProject.id}/tasks/${task.id}`))
    batch.commit()

    setTaskProjectFeedsChain(currentProject, newProject, task, oldAssignee, newAssignee)
}

export async function setTaskParentGoal(projectId, taskId, task, goal, externalBatch) {
    const goalId = goal ? goal.id : null
    const parentGoalIsPublicFor = goal ? goal.isPublicFor : null
    const lockKey = goal && goal.lockKey ? goal.lockKey : ''
    const batch = externalBatch ? externalBatch : new BatchWrapper(getDb())

    if (task.parentId) {
        await deleteSubTaskFromParent(projectId, taskId, task, batch)
        updateTaskData(
            projectId,
            taskId,
            {
                parentId: null,
                isSubtask: false,
                parentGoalId: goalId,
                parentGoalIsPublicFor,
                lockKey,
                sortIndex: generateSortIndex(),
            },
            batch
        )
    } else {
        updateTaskData(
            projectId,
            taskId,
            {
                parentGoalId: goalId,
                parentGoalIsPublicFor,
                lockKey,
                sortIndex: generateSortIndex(),
            },
            batch
        )
        updateSubtasksState(projectId, task.subtaskIds, { parentGoalId: goalId, parentGoalIsPublicFor, lockKey }, batch)
    }

    if (!externalBatch) batch.commit()

    setTaskParentGoalFeedsChain(projectId, taskId, goalId, task.parentGoalId, task)
}

export async function setTaskDueDate(
    projectId,
    taskId,
    dueDate,
    task,
    isObservedTask,
    externalBatch,
    fromSetTaskFocus
) {
    const { currentUser } = store.getState()
    const currentUserId = currentUser.uid

    const batch = externalBatch ? externalBatch : new BatchWrapper(getDb())
    const newSortIndex = generateSortIndex()
    const commonFields = {
        sortIndex: fromSetTaskFocus ? generateSortIndexForTaskInFocusInTime() : newSortIndex,
    }
    if (!isObservedTask && dueDate > task.dueDate) {
        commonFields.timesPostponed = firebase.firestore.FieldValue.increment(1)
        logEvent('task_postponed')
    }
    if (task.parentId) {
        await deleteSubTaskFromParent(projectId, taskId, task, batch)
        updateTaskData(
            projectId,
            taskId,
            {
                parentId: null,
                isSubtask: false,
                dueDate,
                ...commonFields,
            },
            batch
        )
    } else {
        const updateData = isObservedTask
            ? {
                  [`dueDateByObserversIds.${currentUserId}`]: dueDate,
              }
            : { dueDate }
        updateTaskData(
            projectId,
            taskId,
            {
                ...updateData,
                ...commonFields,
            },
            batch
        )

        const subtasksUpdate =
            !isObservedTask && dueDate > task.dueDate
                ? { ...updateData, timesPostponed: firebase.firestore.FieldValue.increment(1) }
                : updateData
        updateSubtasksState(projectId, task.subtaskIds, subtasksUpdate, batch)
    }

    const endOfToday = moment().endOf('day').valueOf()
    if (endOfToday < dueDate) {
        const assignee = TasksHelper.getUserInProject(projectId, task.userId)
        if (assignee.inFocusTaskId === task.id) updateFocusedTask(task.userId, projectId, null, null, null)
    }

    if (!externalBatch) await batch.commit()
    setTaskDueDateFeedsChain(projectId, taskId, dueDate, task, isObservedTask)
}

export async function setTaskToBacklog(projectId, taskId, task, isObservedTask, externalBatch) {
    const { loggedUser, currentUser } = store.getState()
    const currentUserId = currentUser.uid
    const batch = externalBatch ? externalBatch : new BatchWrapper(getDb())
    const commonFields = {
        sortIndex: generateSortIndex(),
        timesPostponed: firebase.firestore.FieldValue.increment(1),
    }
    if (task.parentId) {
        await deleteSubTaskFromParent(projectId, taskId, task, batch)
        updateTaskData(
            projectId,
            taskId,
            {
                ...commonFields,
                parentId: null,
                isSubtask: false,
                dueDate: Number.MAX_SAFE_INTEGER,
            },
            batch
        )
    } else {
        const updateData = isObservedTask
            ? { [`dueDateByObserversIds.${currentUserId}`]: Number.MAX_SAFE_INTEGER }
            : { dueDate: Number.MAX_SAFE_INTEGER }

        updateTaskData(
            projectId,
            taskId,
            {
                ...commonFields,
                ...updateData,
            },
            batch
        )

        const subtasksUpdate = { ...updateData, timesPostponed: firebase.firestore.FieldValue.increment(1) }
        updateSubtasksState(projectId, task.subtaskIds, subtasksUpdate, batch)
    }
    if (!externalBatch) await batch.commit()
    setTaskToBacklogFeedsChain(projectId, taskId, task, isObservedTask)
}

export async function setTaskShared(projectId, taskId, shared) {
    updateTaskData(projectId, taskId, { shared: shared }, null)
}

export async function stopObservingTask(
    projectId,
    task,
    userIdStopingObserving,
    comment,
    assigneeEstimation,
    workflow,
    selectedNextStepIndex,
    checkBoxId
) {
    store.dispatch(startLoadingData())
    const { loggedUser } = store.getState()
    const ownerIsWorkstream = task?.userId?.startsWith(WORKSTREAM_ID_PREFIX)

    const taskIsMovedInWorkflow = selectedNextStepIndex !== null

    if (taskIsMovedInWorkflow && ownerIsWorkstream) {
        const taskOwner = TasksHelper.getTaskOwner(task.userId, projectId)
        await setTaskAssignee(projectId, task.id, loggedUser.uid, taskOwner, loggedUser, { ...task }, false)
    }

    if (taskIsMovedInWorkflow) {
        const { stepHistory } = task
        const stepsIds = getWorkflowStepsIdsSorted(workflow)
        const stepToMoveId = getWorkflowStepId(selectedNextStepIndex, stepsIds)
        const commentType =
            comment && comment.length > 0
                ? getCommentDirectionWhenMoveTaskInTheWorklfow(selectedNextStepIndex, stepsIds, stepHistory)
                : STAYWARD_COMMENT
        const estimations = { ...task.estimations, [OPEN_STEP]: assigneeEstimation }

        if (task.userIds.length === 1) {
            const taskToProcess = ownerIsWorkstream
                ? { ...task, userId: loggedUser.uid, userIds: [loggedUser.uid], currentReviewerId: loggedUser.uid }
                : task
            moveTasksFromOpen(projectId, taskToProcess, stepToMoveId, comment, commentType, estimations, checkBoxId)
        } else {
            moveTasksFromMiddleOfWorkflow(projectId, task, stepToMoveId, comment, commentType, estimations, checkBoxId)
        }
    }

    const updateData = {}

    const updateEstimation = !taskIsMovedInWorkflow && assigneeEstimation !== task.estimations[OPEN_STEP]
    if (updateEstimation) {
        updateData[`estimations.${OPEN_STEP}`] = assigneeEstimation
    }

    if (userIdStopingObserving) {
        updateData.observersIds = firebase.firestore.FieldValue.arrayRemove(userIdStopingObserving)
        updateData[`dueDateByObserversIds.${userIdStopingObserving}`] = firebase.firestore.FieldValue.delete()
        updateData[`estimationsByObserverIds.${userIdStopingObserving}`] = firebase.firestore.FieldValue.delete()
    }

    const batch = new BatchWrapper(getDb())
    updateTaskData(projectId, task.id, { ...updateData }, batch)
    updateSubtasksState(projectId, task.subtaskIds, updateData, batch)
    batch.commit()

    store.dispatch(stopLoadingData())

    if (!taskIsMovedInWorkflow && comment) {
        updateNewAttachmentsData(projectId, comment).then(commentWithAttachments => {
            createObjectMessage(projectId, task.id, commentWithAttachments, 'tasks', STAYWARD_COMMENT, null, null)
        })
    }

    feedsChainInStopObservingTask(projectId, task, userIdStopingObserving, assigneeEstimation, updateEstimation)
}

export async function moveTasksFromMiddleOfWorkflow(
    projectId,
    task,
    stepToMoveId,
    comment,
    commentType,
    estimations,
    checkBoxId
) {
    const { loggedUser } = store.getState()
    const { parentId, subtaskIds, userId, stepHistory, userIds } = task

    if (comment) createObjectMessage(projectId, task.id, comment, 'tasks', commentType, null, null)

    let updateData
    let workflow
    let forwardDirection

    if (stepToMoveId === OPEN_STEP) {
        forwardDirection = false
        updateData = {
            userIds: [userId],
            stepHistory: [OPEN_STEP],
            currentReviewerId: userId,
            completed: null,
            dueDate: Date.now(),
            completedTime: null,
        }
    } else if (stepToMoveId === DONE_STEP) {
        forwardDirection = true
        updateData = {
            currentReviewerId: DONE_STEP,
            completed: Date.now(),
        }
    } else {
        workflow = getUserWorkflow(projectId, userId)
        const workflowStepsIds = Object.keys(workflow).sort(chronoKeysOrder)
        const stepToMoveIndex = workflowStepsIds.indexOf(stepToMoveId)
        const currentStepId = stepHistory[stepHistory.length - 1]
        const currentStepIndex = workflowStepsIds.indexOf(currentStepId)
        forwardDirection = stepToMoveIndex > currentStepIndex

        if (forwardDirection) {
            const { reviewerUid } = workflow[stepToMoveId]
            updateData = {
                userIds: [...userIds, reviewerUid],
                currentReviewerId: reviewerUid,
                completed: Date.now(),
                stepHistory: [...stepHistory, stepToMoveId],
                dueDate: Date.now(),
            }
        } else {
            const newUserIds = [task.userId]
            const newStepHistory = [OPEN_STEP]
            let newCurrentReviewerId = task.userId

            for (let i = 0; i < workflowStepsIds.length; i++) {
                const stepId = workflowStepsIds[i]
                const { reviewerUid } = workflow[stepId]
                if (stepId === stepToMoveId) {
                    newStepHistory.push(stepId)
                    newUserIds.push(reviewerUid)
                    newCurrentReviewerId = reviewerUid
                    break
                } else if (stepHistory.includes(stepId)) {
                    newStepHistory.push(stepId)
                    newUserIds.push(reviewerUid)
                }
            }

            updateData = {
                userIds: newUserIds,
                stepHistory: newStepHistory,
                currentReviewerId: newCurrentReviewerId,
                completed: Date.now(),
            }
        }
    }

    if (!task.parentId && forwardDirection) {
        const reviewerId = userIds[userIds.length - 1]
        earnGold(projectId, reviewerId, MAX_GOLD_TO_EARN_BY_CHECK_TASKS, checkBoxId)
    }

    const batch = new BatchWrapper(getDb())

    if (stepToMoveId === DONE_STEP) {
        const taskEstimation = estimations[OPEN_STEP] ? estimations[OPEN_STEP] : 0
        if (!task.parentId) {
            updateXpByDoneTask(userId, taskEstimation, firebase, getDb(), projectId)
            if (workflow) updateXpByDoneForAllReviewers(estimations, workflow, firebase, getDb(), projectId)
        }
        updateStatistics(projectId, userId, taskEstimation, false, false, null, batch)

        logDoneTasks(task.userId, loggedUser.uid, true)
    }

    updateTaskData(
        projectId,
        task.id,
        {
            ...updateData,
            done: stepToMoveId === DONE_STEP,
            inDone: stepToMoveId === DONE_STEP,
            sortIndex: generateSortIndex(),
            estimations,
        },
        batch
    )

    parentId
        ? await promoteSubtaskToTask(projectId, task, batch)
        : updateSubtasksState(
              projectId,
              subtaskIds,
              { ...updateData, parentDone: stepToMoveId === DONE_STEP, inDone: stepToMoveId === DONE_STEP },
              batch
          )

    batch.commit()

    const assignee = TasksHelper.getUserInProject(projectId, task.userId)
    if (assignee.inFocusTaskId === task.id) updateFocusedTask(task.userId, projectId, null, null, null)

    moveTasksinWorkflowFeedsChain(projectId, task, stepToMoveId, workflow, estimations)
}

const getTaskCompletedTime = task => {
    const { loggedUserProjectsMap, loggedUser } = store.getState()
    const {
        uid: loggedUserId,
        activeTaskId,
        activeTaskProjectId,
        activeTaskStartingDate,
        firstLoginDateInDay,
    } = loggedUser
    const { id: taskId, userId, estimations, calendarData, autoEstimation } = task

    if (calendarData) {
        const endTimeForAllDayCalendarTasks = moment(firstLoginDateInDay).add(8, 'hours').valueOf()
        const { startDateTimestamp, endDateTimestamp } = getCalendarTaskStartAndEndTimestamp(
            calendarData,
            firstLoginDateInDay,
            endTimeForAllDayCalendarTasks
        )
        return { startTime: startDateTimestamp, endTime: endDateTimestamp }
    } else {
        const currentTime = moment().valueOf()
        const baseStartTime = loggedUserId !== userId || activeTaskId !== taskId ? currentTime : activeTaskStartingDate

        const estimation = estimations[OPEN_STEP] || 0
        const { startDate, endDate } = getRoundedStartAndEndDates(baseStartTime, estimation)

        const canExtendEstimation =
            estimation !== 0 && getTaskAutoEstimation(activeTaskProjectId, autoEstimation, loggedUserProjectsMap)

        if (!canExtendEstimation && currentTime > endDate.valueOf()) {
            const MILLISECONDS_IN_MINUTE = 60000
            const newEstimation = Math.floor((currentTime - startDate.valueOf()) / MILLISECONDS_IN_MINUTE)
            const { endDate: extendedEndDate } = getRoundedStartAndEndDates(baseStartTime, newEstimation)
            return { startTime: startDate.valueOf(), endTime: extendedEndDate.valueOf() }
        } else {
            return { startTime: startDate.valueOf(), endTime: endDate.valueOf() }
        }
    }
}

export async function moveTasksFromOpen(projectId, task, stepToMoveId, comment, commentType, estimations, checkBoxId) {
    const { loggedUser } = store.getState()
    const loggedUserId = loggedUser.uid
    const { parentId, subtaskIds, userId } = task

    if (comment) createObjectMessage(projectId, task.id, comment, 'tasks', commentType, null, null)

    const ownerIsWorkstream = userId.startsWith(WORKSTREAM_ID_PREFIX)
    const newUserId = ownerIsWorkstream ? loggedUserId : userId

    let updateData
    let workflow = getUserWorkflow(projectId, newUserId)

    const completedTime = getTaskCompletedTime(task)

    if (stepToMoveId === DONE_STEP) {
        updateData = {
            userId: newUserId,
            userIds: [newUserId],
            currentReviewerId: DONE_STEP,
            completed: Date.now(),
            completedTime,
        }
    } else {
        const { reviewerUid } = workflow[stepToMoveId]
        updateData = {
            userId: newUserId,
            userIds: [newUserId, reviewerUid],
            currentReviewerId: reviewerUid,
            completed: Date.now(),
            stepHistory: [OPEN_STEP, stepToMoveId],
            completedTime,
        }
    }

    createRecurrentTask(projectId, task.id)

    const ownerIsTeamMeber = !!TasksHelper.getUserInProject(projectId, task.userId)

    if (!task.parentId && ownerIsTeamMeber) earnGold(projectId, newUserId, MAX_GOLD_TO_EARN_BY_CHECK_TASKS, checkBoxId)

    const batch = new BatchWrapper(getDb())

    if (stepToMoveId === DONE_STEP) {
        if (ownerIsTeamMeber) {
            const taskEstimation = estimations[OPEN_STEP] ? estimations[OPEN_STEP] : 0
            if (!task.parentId) {
                updateXpByDoneTask(newUserId, taskEstimation, firebase, getDb(), projectId)
                if (workflow) updateXpByDoneForAllReviewers(estimations, workflow, firebase, getDb(), projectId)
            }
            updateStatistics(projectId, newUserId, taskEstimation, false, false, null, batch)
        }

        logDoneTasks(task.userId, loggedUser.uid, workflow ? true : false)
    }

    if (ownerIsWorkstream) {
        const wormstream = getWorkstreamInProject(projectId, userId)
        setTaskAssignee(projectId, task.id, loggedUserId, wormstream, loggedUser, task, false, null)
    }

    updateTaskData(
        projectId,
        task.id,
        {
            ...updateData,
            done: stepToMoveId === DONE_STEP,
            inDone: stepToMoveId === DONE_STEP,
            sortIndex: generateSortIndex(),
            estimations,
        },
        batch
    )

    parentId
        ? await promoteSubtaskToTask(projectId, task, batch)
        : updateSubtasksState(
              projectId,
              subtaskIds,
              { ...updateData, parentDone: stepToMoveId === DONE_STEP, inDone: stepToMoveId === DONE_STEP },
              batch
          )

    batch.commit().then(() => {
        moveToTomorrowGoalReminderDateIfThereAreNotMoreTasks(projectId, task)
    })

    const assignee = TasksHelper.getUserInProject(projectId, task.userId)
    if (assignee && assignee.inFocusTaskId === task.id) updateFocusedTask(task.userId, projectId, null, null, null)

    moveTasksinWorkflowFeedsChain(projectId, task, stepToMoveId, workflow, estimations)
}

export async function moveTasksFromDone(projectId, task, stepToMoveId) {
    const { stepHistory, parentId, subtaskIds, userId, estimations } = task

    let workflow
    let updateData

    if (stepToMoveId === OPEN_STEP) {
        updateData = {
            userIds: [task.userId],
            stepHistory: [OPEN_STEP],
            currentReviewerId: task.userId,
            completed: null,
            dueDate: Date.now(),
            completedTime: null,
        }
    } else {
        workflow = getUserWorkflow(projectId, task.userId)
        const workflowStepsIds = Object.keys(workflow).sort(chronoKeysOrder)

        const newUserIds = [task.userId]
        const newStepHistory = [OPEN_STEP]
        let newCurrentReviewerId = task.userId

        for (let i = 0; i < workflowStepsIds.length; i++) {
            const stepId = workflowStepsIds[i]
            const { reviewerUid } = workflow[stepId]
            if (stepId === stepToMoveId) {
                newStepHistory.push(stepId)
                newUserIds.push(reviewerUid)
                newCurrentReviewerId = reviewerUid
                break
            } else if (stepHistory.includes(stepId)) {
                newStepHistory.push(stepId)
                newUserIds.push(reviewerUid)
            }
        }

        updateData = {
            userIds: newUserIds,
            stepHistory: newStepHistory,
            currentReviewerId: newCurrentReviewerId,
            completed: Date.now(),
            dueDate: Date.now(),
        }
    }

    const batch = new BatchWrapper(getDb())

    const ownerIsTeamMeber = !!TasksHelper.getUserInProject(projectId, task.userId)

    if (ownerIsTeamMeber) {
        updateStatistics(projectId, userId, estimations[OPEN_STEP], true, false, task.completed, batch)
    }

    updateTaskData(
        projectId,
        task.id,
        {
            ...updateData,
            done: false,
            inDone: false,
            sortIndex: generateSortIndex(),
        },
        batch
    )

    parentId
        ? await promoteSubtaskToTask(projectId, task, batch)
        : updateSubtasksState(projectId, subtaskIds, { ...updateData, parentDone: false, inDone: false }, batch)

    batch.commit()

    moveTasksinWorkflowFeedsChain(projectId, task, stepToMoveId, workflow, task.estimations)
}

export async function setTaskStatus(
    projectId,
    taskId,
    isDone,
    taskOwnerUid,
    task,
    comment,
    createDoneFeed,
    oldEstimation,
    newEstimation
) {
    const taskBatch = new BatchWrapper(getDb())
    const completed = isDone ? Date.now() : firebase.firestore.FieldValue.delete()

    const updateData = {
        done: isDone,
        inDone: task.parentId ? task.inDone : isDone,
        recurrence: task.recurrence,
    }

    if (!task.parentId) {
        updateData.completed = completed
        updateData.sortIndex = task.done && !isDone ? generateSortIndex() : task.sortIndex
        updateData.currentReviewerId = isDone ? DONE_STEP : task.userId
    }

    updateTaskData(projectId, taskId, updateData, taskBatch)

    if (isDone) {
        updateSubtasksCompletedState(projectId, task.subtaskIds, completed, taskBatch)
    }

    if (task.done && !isDone) {
        updateSubtasksState(
            projectId,
            task.subtaskIds,
            {
                parentDone: false,
                currentReviewerId: task.userId,
                inDone: false,
            },
            taskBatch
        )
    }
    if (!task.done && isDone) {
        updateSubtasksState(
            projectId,
            task.subtaskIds,
            {
                parentDone: true,
                currentReviewerId: DONE_STEP,
                inDone: true,
            },
            taskBatch
        )
    }

    const taskRealOwner = TasksHelper.getTaskOwner(taskOwnerUid, projectId)
    const statisticUserUid = taskRealOwner.recorderUserId ? store.getState().loggedUser.uid : taskOwnerUid

    if (isDone) {
        updateStatistics(projectId, statisticUserUid, task.estimations[OPEN_STEP], false, false, null, taskBatch)
    } else {
        updateStatistics(
            projectId,
            statisticUserUid,
            task.estimations[OPEN_STEP],
            true,
            false,
            task.completed,
            taskBatch
        )
    }

    taskBatch.commit()

    const batch = new BatchWrapper(getDb())
    if (comment) {
        createObjectMessage(projectId, taskId, comment, 'tasks', STAYWARD_COMMENT, null, null)
    }

    if (isDone) {
        if (!task.parentId) {
            updateXpByDoneTask(statisticUserUid, task.estimations[OPEN_STEP], firebase, getDb(), projectId)
        }

        if (task.userIds.length === 1) {
            createRecurrentTask(projectId, task.id)
        }
        logEvent('done_task', {
            taskOwnerUid: task.userId,
            effectingUserUid: store.getState().loggedUser.uid,
            isInWorkflow: task.userIds.length > 1,
        })
        if (createDoneFeed) {
            if (oldEstimation !== newEstimation) {
                await createTaskAssigneeEstimationChangedFeed(projectId, task.id, oldEstimation, newEstimation, batch)
            }

            updateSubtasksState(projectId, task.subtaskIds, {
                parentDone: true,
                currentReviewerId: DONE_STEP,
                inDone: true,
            })

            await createTaskCheckedDoneFeed(projectId, task, taskId, batch)

            const followTaskData = {
                followObjectsType: FOLLOWER_TASKS_TYPE,
                followObjectId: taskId,
                followObject: task,
                feedCreator: store.getState().loggedUser,
            }
            await tryAddFollower(projectId, followTaskData, batch)
        }
    } else {
        await createTaskUncheckedDoneFeed(projectId, task, taskId, batch)
    }
    batch.commit()
}

export const updateSubtasksCompletedState = (projectId, subtaskIds, completed, externalBatch) => {
    if (subtaskIds && subtaskIds.length > 0) {
        const batch = externalBatch ? externalBatch : new BatchWrapper(getDb())
        subtaskIds.forEach(subtaskId => {
            updateTaskData(projectId, subtaskId, { completed }, batch)
        })
        if (!externalBatch) {
            batch.commit()
        }
    }
}

export const promoteSubtask = async (projectId, task) => {
    const { loggedUser } = store.getState()
    const taskId = task.id
    const promotedTask = { ...task, parentId: null, isSubtask: false }
    const batch = new BatchWrapper(getDb())
    await promoteSubtaskToTask(projectId, task, batch)
    await createSubtaskPromotedFeed(projectId, promotedTask, taskId, task.parentId, batch)
    const followTaskData = {
        followObjectsType: 'tasks',
        followObjectId: taskId,
        followObject: promotedTask,
        feedCreator: loggedUser,
    }
    await tryAddFollower(projectId, followTaskData, batch)
    batch.commit()
}

async function promoteSubtaskToTask(projectId, task, externalBatch) {
    const taskId = task.id
    const batch = externalBatch ? externalBatch : new BatchWrapper(getDb())
    await deleteSubTaskFromParent(projectId, taskId, task, batch)
    const updateData = {
        parentDone: false,
        parentId: null,
        isSubtask: false,
        sortIndex: generateSortIndex(),
        inDone: false,
    }

    if (task.parentDone) {
        if (!task.done) {
            updateData.completed = null
            updateData.currentReviewerId = task.userId
            updateData.stepHistory = [OPEN_STEP]
            updateData.userIds = [task.userId]
            updateData.done = false
            updateData.inDone = false
        }
    } else if (task.done) {
        updateData.done = false
        updateData.inDone = false
    }

    updateTaskData(projectId, taskId, updateData, batch)
    if (!externalBatch) {
        batch.commit()
    }
}

export const updateSuggestedTask = (projectId, taskId, object) => {
    updateTaskData(projectId, taskId, object, null)
}

export const nextStepSuggestedTask = (projectId, targetStepId, task, estimations, comment, checkBoxId) => {
    const { subtaskIds } = task
    const updateData = { suggestedBy: null }
    updateTaskData(projectId, task.id, updateData, null)
    updateSubtasksState(projectId, subtaskIds, updateData, null)
    moveTasksFromOpen(projectId, task, targetStepId, comment, FORDWARD_COMMENT, estimations, checkBoxId)
}

export const updateSubtasksState = (projectId, subtaskIds, updateData, externalBatch) => {
    if (subtaskIds && subtaskIds.length > 0) {
        const batch = externalBatch ? externalBatch : new BatchWrapper(getDb())
        subtaskIds.forEach(subtaskId => {
            updateTaskData(projectId, subtaskId, updateData, batch)
        })
        if (!externalBatch) {
            batch.commit()
        }
    }
}

export const getDateToMoveTaskInAutoTeminder = (timesPostponed, isObservedTask) => {
    let date = moment()

    if (!timesPostponed || isObservedTask) {
        date.add(1, 'day')
    } else if (timesPostponed === 1) {
        date.add(2, 'day')
    } else if (timesPostponed === 2) {
        date.add(4, 'day')
    } else if (timesPostponed === 3) {
        date.add(8, 'day')
    } else if (timesPostponed === 4) {
        date.add(16, 'day')
    } else if (timesPostponed === 5) {
        date.add(32, 'day')
    } else if (timesPostponed === 6) {
        date.add(64, 'day')
    } else if (timesPostponed === 7) {
        date.add(128, 'day')
    } else if (timesPostponed === 8) {
        date.add(256, 'day')
    } else {
        date = BACKLOG_DATE_NUMERIC
    }
    return date
}

export async function autoReminderMultipleTasks(tasks) {
    store.dispatch(startLoadingData())

    const sortedTasks = [...tasks].sort((a, b) => a.sortIndex - b.sortIndex)
    const batch = new BatchWrapper(getDb())
    const promises = []
    for (let task of sortedTasks) {
        const dueDate = getDateToMoveTaskInAutoTeminder(task.timesPostponed, task.isObservedTask)
        const dateTimestamp = dueDate === BACKLOG_DATE_NUMERIC ? BACKLOG_DATE_NUMERIC : dueDate.valueOf()
        promises.push(setTaskDueDate(task.projectId, task.id, dateTimestamp, task, task.isObservedTask, batch))
    }
    await Promise.all(promises)
    await batch.commit()

    store.dispatch([setSelectedTasks(null, true), stopLoadingData()])
}
