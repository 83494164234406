import React from 'react'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { useSelector } from 'react-redux'

import styles, { colors, windowTagStyle } from '../../styles/global'
import Icon from '../../Icon'
import { translate } from '../../../i18n/TranslationService'
import { checkIfCalendarConnected } from '../../../utils/backends/firestore'
import { getProjectIdWhereCalendarIsConnected } from '../MyDayTasks/MyDayOpenTasks/myDayOpenTasksHelper'

export default function SyncCalendar() {
    const apisConnected = useSelector(state => state.loggedUser.apisConnected)
    const smallScreenNavigation = useSelector(state => state.smallScreenNavigation)
    const isMiddleScreen = useSelector(state => state.isMiddleScreen)

    const projectIdWhereCalendarIsConnected = getProjectIdWhereCalendarIsConnected(apisConnected)

    const sync = () => {
        checkIfCalendarConnected(projectIdWhereCalendarIsConnected)
    }

    return !!projectIdWhereCalendarIsConnected ? (
        <TouchableOpacity
            style={[localStyles.tag, (smallScreenNavigation || isMiddleScreen) && localStyles.tagMobile]}
            onPress={sync}
        >
            <View style={localStyles.icon}>
                <Icon name={'refresh-cw'} size={16} color={colors.Text03} />
            </View>
            {!smallScreenNavigation && !isMiddleScreen && (
                <Text style={[styles.subtitle2, localStyles.text, windowTagStyle()]}>{translate('Sync calendar')}</Text>
            )}
        </TouchableOpacity>
    ) : null
}

const localStyles = StyleSheet.create({
    tag: {
        flexDirection: 'row',
        borderRadius: 50,
        justifyContent: 'center',
        alignItems: 'center',
        height: 24,
        alignSelf: 'flex-start',
        borderWidth: 1,
        borderColor: colors.Text03,
        paddingHorizontal: 4,
    },
    tagMobile: {
        width: 24,
        height: 24,
    },
    text: {
        color: colors.Text03,
        marginLeft: 6,
        marginRight: 4,
    },
    icon: {
        flexDirection: 'row',
        alignSelf: 'center',
    },
})
