import React from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'

import AllProjectsEmptyInbox from '../../../TaskListView/OpenTasksView/AllProjectsEmptyInbox'
import MyDayDoneTasksList from './MyDayDoneTasksList'

export default function MyDayDoneTasks() {
    const myDayDoneTasksAmount = useSelector(state => state.myDayDoneTasks.length)
    const tasksLoaded = useSelector(state => state.myDayDoneTasksByProject.loaded)
    return (
        <View style={{ marginTop: 16, marginBottom: 32 }}>
            {tasksLoaded && (myDayDoneTasksAmount > 0 ? <MyDayDoneTasksList /> : <AllProjectsEmptyInbox />)}
        </View>
    )
}
