import React from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'

import AllProjectsEmptyInbox from '../../../TaskListView/OpenTasksView/AllProjectsEmptyInbox'
import MyDayWorkflowTasksList from './MyDayWorkflowTasksList'

export default function MyDayWorkflowTasks() {
    const myDayWorkflowTasksAmount = useSelector(state => state.myDayWorkflowTasks.length)
    const tasksLoaded = useSelector(state => state.myDayWorkflowTasksByProject.loaded)
    return (
        <View style={{ marginTop: 16, marginBottom: 32 }}>
            {tasksLoaded && (myDayWorkflowTasksAmount > 0 ? <MyDayWorkflowTasksList /> : <AllProjectsEmptyInbox />)}
        </View>
    )
}
