import React from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'

import MyDayOpenTasksList from './MyDayOpenTasksList'
import MyDayDateLine from '../MyDayDateLine'

export default function MyDaySelectedTasks() {
    const myDaySelectedTasks = useSelector(state => state.myDaySelectedTasks)

    return (
        <>
            <MyDayDateLine tasks={myDaySelectedTasks} date={moment().format('YYYYMMDD')} />
            <MyDayOpenTasksList tasks={myDaySelectedTasks} />
        </>
    )
}
